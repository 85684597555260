import styled from "styled-components";

export const MainContainerDiv = styled.div`
  background-color: var(--WHITE);
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
export const MainContainerAndFooterDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  min-height: 100vh;
  min-width: 100vw;
  background-color: var(--MERIT-BLUE);
`;

export const SecondaryContainerDiv = styled.div`
  padding-top: 0px;
  margin-left: 0%;
  margin-right: 0%;
  background-color: var(--MERIT-GREEN);

  @media (max-width: 870px) {
    margin-left: 0%;
    margin-right: 0%;
  }
  @media (max-width: 760px) {
    margin-left: 0%;
    margin-right: 0%;
  }
`;
