import "./App.css";
import { Home } from "./pages/Home/Home";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { MenuBar } from "./components/MenuBar/MenuBar";
import React, { useEffect } from "react";
import {
  MainContainerAndFooterDiv,
  MainContainerDiv,
  SecondaryContainerDiv,
} from "./components/MainContainer/styles";
import { Footer } from "./components/Footer/Footer";
import { MeritLogoContainer } from "./components/MenuBar/styles";
import { PageContainer } from "./components/PageContainer/PageContainer";
import { CollegePlanning } from "./pages/FinancialPlanningPackages/College Planning/CollegePlanning";
import { ComprehensiveFinancialPlan } from "./pages/FinancialPlanningPackages/Comprehensive Financial Plan/ComprehensiveFinancialPlan";
import { RetirementRoadMap } from "./pages/FinancialPlanningPackages/Retirement Road Map/RetirementRoadMap";
import { BottomLinePlanningPackage } from "./pages/FinancialPlanningPackages/Bottom Line Planning Package/BottomLinePlanningPackage";
import { AssetAllocationPortfolioReview } from "./pages/FinancialPlanningPackages/Asset Allocation Portfolio Review/AssetAllocationPortfolioReview";
import { FinancialPlanningServices } from "./pages/Services/Financial Planning Services/FinancialPlanningServices";
import { DivorceFinancialPlanning } from "./pages/Services/Divorce Financial Planning/DivorceFinancialPlanning";
import { InvestmentManagement } from "./pages/Services/Investment Management/InvestmentManagement";
import { ElizabethCox } from "./pages/About/Elizabeth Cox/ElizabethCox";
import { MichaelTerry } from "./pages/About/Michael Terry/MichaelTerry";
import { HrefnaMartin } from "./pages/About/Hrefna Martin/HrefnaMartin";
import { MelissaAdams } from "./pages/About/Melissa Adams/MelissaAdams";
import { EdEsposito } from "./pages/About/Ed Esposito/EdEsposito";
import { PayNow } from "./pages/Payment/Pay Now/PayNow";
import { Contact } from "./pages/Contact/Contact";
import { Services } from "./pages/Services/Services";
import { Portal } from "./pages/Portal/Portal";
import { FinancialPlanningPackages } from "./pages/FinancialPlanningPackages/FinancialPlanningPackages";
import { Payment } from "./pages/Payment/Payment";
import { About } from "./pages/About/About";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { MainRouteContainerDiv } from "./styles";
import { OngoingAdvisoryService } from "./pages/Services/Ongoing Advisory Service/OngoingAdvisoryService";

export const TRACKING_ID = "UA-215916739-1";

function App() {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/");
  }, []);
  return (
    <Router>
      <Helmet>
        <title>Merit Financial Partners | With You Every Step of the Way</title>
        <meta
          name={"description"}
          content={
            "Merit Financial Partners, LLC is an independent, fee only financial planning and investment advisory firm with locations in New York and Connecticut."
          }
        />
        <meta
          name={"keywords"}
          content={
            "Merit Financial Planning, Merit Financial Partners, Westport Financial Planning, Fee only financial planners, Retirement planning, divorce financial planning, tax planning, fiduciary standard, certified financial planner"
          }
        />
      </Helmet>
      <div
        style={{
          maxWidth: "100vw",
          overflow: "hidden",
          backgroundColor: "--var(MERIT-BLUE)",
        }}
      >
        <MainContainerAndFooterDiv>
          <MainContainerDiv>
            <MeritLogoContainer />
            <MenuBar />
            <SecondaryContainerDiv>
              <Routes>
                <Route path="/" exact element={<Home />} />
                <Route
                  path="/financial-planning-packages"
                  exact
                  element={
                    <MainRouteContainerDiv>
                      <PageContainer title={"Financial Planning Packages"} />
                      <FinancialPlanningPackages />
                    </MainRouteContainerDiv>
                  }
                />
                <Route
                  path="/packages-college"
                  exact
                  element={
                    <MainRouteContainerDiv>
                      <PageContainer title={"College Planning"} />
                      <CollegePlanning />
                    </MainRouteContainerDiv>
                  }
                />
                <Route
                  path="/comprehensive-financial-plan"
                  exact
                  element={
                    <MainRouteContainerDiv>
                      <PageContainer title={"Comprehensive Financial Plan"} />
                      <ComprehensiveFinancialPlan />
                    </MainRouteContainerDiv>
                  }
                />
                <Route
                  path="/retirement-road-map"
                  exact
                  element={
                    <MainRouteContainerDiv>
                      <PageContainer title={"Retirement Road Map"} />
                      <RetirementRoadMap />
                    </MainRouteContainerDiv>
                  }
                />
                <Route
                  path="/packages-bottom-line"
                  exact
                  element={
                    <MainRouteContainerDiv>
                      <PageContainer
                        title={"The Bottom Line Planning Package"}
                      />
                      <BottomLinePlanningPackage />
                    </MainRouteContainerDiv>
                  }
                />
                <Route
                  path="/packages-asset-allocation"
                  exact
                  element={
                    <MainRouteContainerDiv>
                      <PageContainer
                        title={"Asset Allocation/Portfolio Review"}
                      />
                      <AssetAllocationPortfolioReview />
                    </MainRouteContainerDiv>
                  }
                />

                {/*SERVICES*/}
                <Route
                  path="/services"
                  exact
                  element={
                    <MainRouteContainerDiv>
                      <PageContainer title={"Services"} />
                      <Services />
                    </MainRouteContainerDiv>
                  }
                />
                <Route
                  path="/services-financial-planning"
                  exact
                  element={
                    <MainRouteContainerDiv>
                      <PageContainer title={"Financial Planning Services"} />
                      <FinancialPlanningServices />
                    </MainRouteContainerDiv>
                  }
                />
                <Route
                  path="/services-divorce-planning"
                  exact
                  element={
                    <MainRouteContainerDiv>
                      <PageContainer title={"Divorce Financial Planning"} />
                      <DivorceFinancialPlanning />
                    </MainRouteContainerDiv>
                  }
                />
                <Route
                  path="/services-investment-management"
                  exact
                  element={
                    <MainRouteContainerDiv>
                      <PageContainer title={"Investment Management"} />
                      <InvestmentManagement />
                    </MainRouteContainerDiv>
                  }
                />
                <Route
                  path="/services-ongoing-advisory"
                  exact
                  element={
                    <MainRouteContainerDiv>
                      <PageContainer title={"Ongoing Advisory Service"} />
                      <OngoingAdvisoryService />
                    </MainRouteContainerDiv>
                  }
                />
                {/*<Route*/}
                {/*  path="/services-ongoing-advisory-premium"*/}
                {/*  exact*/}
                {/*  element={*/}
                {/*    <MainRouteContainerDiv>*/}
                {/*      <PageContainer*/}
                {/*        title={"Premium Ongoing Advisory Service"}*/}
                {/*      />*/}
                {/*      <PremiumOngoingAdvisoryService />*/}
                {/*    </MainRouteContainerDiv>*/}
                {/*  }*/}
                {/*/>*/}
                {/*<Route*/}
                {/*  path="/services-tax-advice"*/}
                {/*  exact*/}
                {/*  element={*/}
                {/*    <MainRouteContainerDiv>*/}
                {/*      <PageContainer title={"Tax Advice"} />*/}
                {/*      <TaxAdviceAndPreperation />*/}
                {/*    </MainRouteContainerDiv>*/}
                {/*  }*/}
                {/*/>*/}
                {/*ABOUT*/}
                <Route
                  path="/about"
                  exact
                  element={
                    <MainRouteContainerDiv>
                      <PageContainer title={"About Us"} />
                      <About />
                    </MainRouteContainerDiv>
                  }
                />
                <Route
                  path="/elizabeth-cox"
                  exact
                  element={
                    <MainRouteContainerDiv>
                      <PageContainer title={"Elizabeth Cox"} />
                      <ElizabethCox />
                    </MainRouteContainerDiv>
                  }
                />
                <Route
                  path="/michael-terry"
                  exact
                  element={
                    <MainRouteContainerDiv>
                      <PageContainer title={"Michael Terry"} />
                      <MichaelTerry />
                    </MainRouteContainerDiv>
                  }
                />
                <Route
                  path="/hrefna-martin"
                  exact
                  element={
                    <MainRouteContainerDiv>
                      <PageContainer title={"Hrefna Martin"} />
                      <HrefnaMartin />
                    </MainRouteContainerDiv>
                  }
                />
                <Route
                  path="/melissa-adams"
                  exact
                  element={
                    <MainRouteContainerDiv>
                      <PageContainer title={"Melissa Adams"} />
                      <MelissaAdams />
                    </MainRouteContainerDiv>
                  }
                />
                <Route
                  path="/ed-esposito"
                  exact
                  element={
                    <MainRouteContainerDiv>
                      <PageContainer title={"Ed Esposito"} />
                      <EdEsposito />
                    </MainRouteContainerDiv>
                  }
                />
                {/*PAY NOW*/}
                <Route
                  path="/pay"
                  exact
                  element={
                    <MainRouteContainerDiv>
                      <PageContainer title={"Pay"} />
                      <Payment />
                    </MainRouteContainerDiv>
                  }
                />
                <Route
                  path="/pay-now"
                  exact
                  element={
                    <MainRouteContainerDiv>
                      <PageContainer title={"Pay Now"} />
                      <PayNow />
                    </MainRouteContainerDiv>
                  }
                />

                {/*CONTACT*/}

                <Route
                  path="/contact"
                  exact
                  element={
                    <MainRouteContainerDiv>
                      <PageContainer title={"Contact"} />
                      <Contact />
                    </MainRouteContainerDiv>
                  }
                />
                {/*Portal*/}

                <Route
                  path="/client-portal"
                  exact
                  element={
                    <MainRouteContainerDiv>
                      <PageContainer title={"Client Portal"} />
                      <Portal />
                    </MainRouteContainerDiv>
                  }
                />

                <Route path="*" element={<Navigate to={{ pathname: "/" }} />} />
              </Routes>
            </SecondaryContainerDiv>
          </MainContainerDiv>
          <Footer />
        </MainContainerAndFooterDiv>
      </div>
    </Router>
  );
}

export default App;
