import { PageContainerDivContent } from "../../../components/PageContainer/styles";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { TRACKING_ID } from "../../../App";

export const ComprehensiveFinancialPlan = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/comprehensive-financial-plan");
  }, []);
  return (
    <PageContainerDivContent>
      <Helmet>
        <title>Comprehensive Financial Plan | Merit Financial Partners</title>
        <meta
          name={"description"}
          content={
            "Are you on the right financial path? When will you be able to retire and what financial adjustments would be advisable for you to take? Our comprehensive financial plan reviews multiple aspects of your current finances and your financial goals. It is the cornerstone to your “Financial Roadmap.”"
          }
        />
      </Helmet>
      <div className="x-container max width offset">
        <div className="x-main full" role="main">
          <article
            id="post-223"
            className="post-223 page type-page status-publish hentry no-post-thumbnail"
          >
            <div className="entry-wrap">
              <div className="entry-content content">
                <p>
                  Are you on the right financial path? When will you be able to
                  retire and what financial adjustments would be advisable for
                  you to take? Our comprehensive financial plan reviews multiple
                  aspects of your current finances and your financial goals. It
                  is the cornerstone to your “Financial Roadmap.”
                </p>
                <p>
                  With a comprehensive financial plan you will feel more
                  confident about your financial decision-making, save more
                  money and feel better about your progress in saving toward
                  financial goals. This package benefits people at all income
                  levels and at any stage of their lives.
                </p>
                <p>The plan may include:</p>
                <ul>
                  <li>
                    <strong>Long-Term Spending</strong>
                    <ul>
                      Lifetime cash flow projection, which includes income
                      projections and tax estimates.
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Education Funding</strong>
                    <ul>
                      <li>
                        Tax-efficient, long-term strategies for paying for
                        college education.
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Investments</strong>
                    <ul>
                      <li>
                        Assistance in preparing an investment policy statement.
                      </li>
                      <li>Identification of time horizon for investments.</li>
                      <li>
                        Recommendation of an asset allocation to match risk,
                        time horizon, and other parameters.
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Retirement</strong>
                    <ul>
                      <li>
                        Review employment-related benefits, qualified plan
                        contributions, and other tax-deferral arrangements.
                        Recommend ways to maximize benefits.
                      </li>
                      <li>
                        Estimate Social Security benefits, and recommend Social
                        Security start date and strategies.
                      </li>
                      <li>
                        Consider healthcare and long-term care in retirement.
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Income Tax</strong>
                    <ul>
                      <li>
                        Tax minimization and deferral strategies, including tax
                        efficient investments and contributions to IRAs,
                        qualified retirement plans, and college savings
                        programs.
                      </li>
                      <li>Planning for Alternative Minimum Tax.</li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Insurance</strong>
                    <ul>
                      <li>Analyze current insurance policies.</li>
                      <li>
                        Recommend types and amounts of coverage for property
                        &amp; casualty, health, major medical, excess liability,
                        disability, life insurance, and long-term care coverage.
                      </li>
                      <li>
                        Provide referrals to insurance professionals, as needed.
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Estate Planning</strong>
                    <ul>
                      <li>
                        Review of current wills, trusts, powers of attorney, and
                        related documents. Recommendation of new or updated
                        documents.
                      </li>
                      <li>
                        Analyze beneficiary designations and proper titling of
                        assets.
                      </li>
                      <li>Discuss desired gifting strategies.</li>
                    </ul>
                  </li>
                </ul>
                <p>Price range $2,500-$5,500.</p>
              </div>
            </div>
          </article>
        </div>
      </div>
    </PageContainerDivContent>
  );
};
