import { PageContainerDiv, H1 } from "./styles";

export const PageContainer = (props: any) => {
  return (
    <PageContainerDiv>
      <H1>{props.title}</H1>
      <hr />
    </PageContainerDiv>
  );
};
