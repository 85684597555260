import { PageContainerDivContent } from "../../../components/PageContainer/styles";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { TRACKING_ID } from "../../../App";
import React, { useEffect } from "react";

export const EdEsposito = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/ed-esposito");
  }, []);
  return (
    <PageContainerDivContent>
      <Helmet>
        <title>Ed Esposito | Merit Financial Partners</title>
        <meta
          name={"description"}
          content={
            "Ed Esposito, EA is an Investment Advisor Representative with Merit Financial Partners, LLC (MFP). He is an Enrolled Agent (EA) admitted to practice before the Internal Revenue Service (IRS) and has been helping clients with their tax and accounting needs for over 4 years. He began his journey to become a fee-only financial planner by following his passion in personal finance with the mission to to help clients with their complete financial picture."
          }
        />
      </Helmet>
      <p>
        Ed Esposito, CFP®, EA is an Investment Advisor Representative with
        Merit Financial Partners, LLC (MFP). He is an Enrolled Agent (EA)
        admitted to practice before the Internal Revenue Service (IRS) and has
        been helping clients with their tax and accounting needs for over 4
        years. He began his journey to become a fee-only financial planner by
        following his passion in personal finance with the mission to to help
        clients with their complete financial picture.
      </p>
      <p>
        Ed is a Certified Financial Planner™ and received his Certificate in
        Financial Planning from New York University. He is thrilled to be a part
        of a team that puts the client first.
      </p>
    </PageContainerDivContent>
  );
};
