import styled from "styled-components";
import "../../../styles.css";
import Logo from "../../../images/brand/Logo.png";
import { NAVBAR_HEIGHT, TOP_LOGO_HEIGHT } from "../../../constants";
export const MenuUnorderedList = styled.ul`
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  position: fixed;
  width: 100%;
  background-color: var(--MERIT-GREEN);
  position: -webkit-sticky; /* Safari */
  position: sticky;
  color: white;
  text-align: center;
`;

export const MenuListItem = styled.li`
  list-style: none;
  text-align: center;
  text-decoration: none;
  color: white;
  display: inline-block;
  font-size: 22px;
  float: none;
  :hover {
    background-color: var(--MERIT-BLUE);
    border-radius: 25px;
  }
  .active {
    background-color: var(--MERIT-BLUE);
    border-radius: 25px;
  }
`;

export const MeritLogoContainer = styled.div`
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: ${TOP_LOGO_HEIGHT};
  background-size: contain;
  background-image: url(${Logo}); ;
`;

export const MenuBarContainer = styled.div`
  padding: 0;
  align-content: center;
  //height: 100%;
  text-align: center;
  //left: 50%;
  width: 100%;
  float: left;
  color: white;
  background-color: var(--MERIT-GREEN);
  border-bottom: 0 solid var(--GLOBAL-DARKGRAY);
  box-shadow: 0 0 2px var(--GLOBAL-DARKGRAY);
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  list-style-type: none;
  height: ${NAVBAR_HEIGHT};
`;

export const LinkWrapper = styled.div``;
