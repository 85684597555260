import {
  CallToActionButtonContainer,
  CallToActionContainer,
  CallToActionHeader,
  CallToActionSubtitle,
  CTATextContainer,
} from "./styles";
import { Button } from "react-bootstrap";
import React, { Fragment } from "react";
import { LogoTicker } from "../Ticker/LogoTicker";
import styled from "styled-components";

const CTAContent = styled.div`
  @media (max-width: 870px) {
    max-width: 70vw;
  }
  margin: 0 10%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  z-index: 2;
`;
export const CallToAction = React.memo(() => {
  return (
    <Fragment>
      <style type="text/css">
        {`

        .btn {
        font-family: "Arial", sans-serif;
        font-weight: bold;
    }
    .btn-merit-green {
      background-color: var(--MERIT-BLUE);
      color: white;
      &:hover {
      color: white;
      background-color: var(--MERIT-BLUE-DARK);}
        }
        .btn-merit-secondary-dark {
        background-color: #212529;
        color: white;
        &:hover {
      color: white;
      background-color: #121316FF;}
        }
        }

    .btn-xxl {
      padding: 1rem 1.5rem;
      font-size: 1.5rem;
    }
    `}
      </style>
      <div style={{ display: "flex" }}>
        <div style={{ display: "grid", position: "relative", width: "100%" }}>
          <CallToActionContainer
            style={{
              gridColumn: "1",
              gridRow: "1",
              paddingBottom: "110px",
              maxWidth: "100vw",
            }}
          >
            <CTAContent>
              <CTATextContainer>
                <CallToActionHeader>
                  Are You Seeking Competent, Objective Financial Advice You Can
                  Trust?
                </CallToActionHeader>
                <CallToActionSubtitle>
                  Merit Financial Partners can help you every step of the way.
                </CallToActionSubtitle>
                <CallToActionButtonContainer>
                  {/*<CallToActionButton*/}
                  {/*  */}
                  {/*>*/}
                  {/*  Learn More*/}
                  {/*</CallToActionButton>*/}
                  {/*<CallToActionButton*/}
                  {/* */}
                  {/*>*/}
                  {/*  Client Login*/}
                  {/*</CallToActionButton>*/}
                  <Button
                    size={"lg"}
                    variant="merit-green"
                    onClick={() => {
                      window.open("services", "_self");
                    }}
                  >
                    Learn More
                  </Button>
                  <Button
                    size={"lg"}
                    variant="merit-secondary-dark"
                    onClick={() => {
                      window.open(
                        "https://main.yhlsoft.com/auth/users/webportal/Merit",
                        "_blank",
                      );
                    }}
                  >
                    Client Login
                  </Button>
                </CallToActionButtonContainer>
              </CTATextContainer>
              <div style={{ width: "100%" }}></div>
            </CTAContent>
          </CallToActionContainer>
          <div
            style={{
              gridColumn: "1",
              gridRow: "1",
              left: "0",
              width: "100%",
              bottom: "1px",
              maxWidth: "100vw",
              transformOrigin: "bottom",
              zIndex: 1,
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            <div
              style={{
                height: "110px",
                backgroundColor: "rgba(255,255,255,0.75)",
                width: "100%",
                maxWidth: "100vw",
                alignContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <LogoTicker />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
});
