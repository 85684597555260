import { ButtonContainer, PaySimpleButton, ZelleButton } from "./styles";
import { PageContainerDivContent } from "../../../components/PageContainer/styles";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { TRACKING_ID } from "../../../App";
import { Helmet } from "react-helmet";

export const PayNow = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/pay-now");
  }, []);
  return (
    <PageContainerDivContent>
      <Helmet>
        <title>Pay Now | Merit Financial Partners</title>
        <meta
          name={"description"}
          content={
            "Pay for an invoice or sign up for a service with Merit Financial Partners."
          }
        />
      </Helmet>
      <ButtonContainer>
        <ZelleButton>Bank-to-Bank Transfer with Zelle®</ZelleButton>
      </ButtonContainer>
      <p style={{ textAlign: "center" }}>
        Send Zelle® payment via online banking platform to:{" "}
        <b>ecox@meritfp.com</b>
      </p>
      <br />
      <p style={{ textAlign: "center" }}>OR</p>
      <br />
      <ButtonContainer>
        <PaySimpleButton
          href={
            "https://meritfinancialpartners.mypaysimple.com/s/make-a-onetime-payment"
          }
          target={"_blank"}
        >
          Pay with Credit Card
        </PaySimpleButton>
      </ButtonContainer>
    </PageContainerDivContent>
  );
};
