import styled from "styled-components";
import "../../../styles.css";

export const PageContainerDiv = styled.div`
  background-color: white;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-top: 40px;
  padding-bottom: 10px;
`;
export const PageContainerDivContent = styled.div`
  background-color: white;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-top: 10px;
  padding-bottom: 40px;
`;
export const H1 = styled.div`
  font-size: 28px;
  font-family: "Arial", sans-serif;
  font-weight: 500;
`;

export const UnorderedList = styled.ul``;
export const ListItem = styled.li`
  list-style-type: circle;
  //color: #781fd7;
  //content: "✓";
  //font-weight: bold;
  //left: -25px;
  //position: absolute;
`;
