import { PageContainerDivContent } from "../../components/PageContainer/styles";
import { CallToActionButton } from "../Home/components/CallToAction/styles";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { TRACKING_ID } from "../../App";

export const Portal = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/client-portal");
  }, []);
  return (
    <PageContainerDivContent style={{ textAlign: "center" }}>
      <Helmet>
        <title>Portal | Merit Financial Partners</title>
        <meta
          name={"description"}
          content={
            "Login or register for Merit Financial Partner's state-of-the-art customer portal."
          }
        />
      </Helmet>
      {/*<CallToActionButton*/}
      {/*  style={{ width: "50%" }}*/}
      {/*  onClick={() => {*/}
      {/*    window.open("https://main.yhlsoft.com/auth/users/register?key=Merit");*/}
      {/*  }}*/}
      {/*>*/}
      {/*  New Client Portal Registration*/}
      {/*</CallToActionButton>*/}
      <br />
      <br />
      <CallToActionButton
        style={{ width: "50%" }}
        onClick={() => {
          window.open("https://main.yhlsoft.com/auth/users/webportal/Merit");
        }}
      >
        Client Login
      </CallToActionButton>
    </PageContainerDivContent>
  );
};
