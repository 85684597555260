import { PageContainerDivContent } from "../../../components/PageContainer/styles";
import { ChecklistContainer } from "../styles";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { TRACKING_ID } from "../../../App";
// import { faCheck } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCoffee } from "@fortawesome/free-solid-svg-icons";

export const DivorceFinancialPlanning = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/divorce-financial-planning");
  }, []);
  return (
    <PageContainerDivContent>
      <Helmet>
        <title>Divorce Financial Planning | Merit Financial Partners</title>
        <meta
          name={"description"}
          content={
            "Divorce may be one of the most emotional and traumatic events in a person’s life. The financial decisions you make during this time can seem overwhelming and can impact your life for years to come."
          }
        />
      </Helmet>
      <div className="entry-wrap">
        <div className="entry-content content">
          <p>
            Divorce may be one of the most emotional and traumatic events in a
            person’s life. The financial decisions you make during this time can
            seem overwhelming and can impact your life for years to come. When
            emotions run high, financial mistakes are often made. In a divorce,
            these mistakes can be permanent and the impact of these consequences
            can be devastating.
          </p>
          <p>
            Working with a financial professional who specializes in financial
            and tax issues specific to divorce, can help you avoid costly
            mistakes. By&nbsp;applying the principles of divorce financial
            planning early on, we can help you
            avoid&nbsp;unworkable&nbsp;outcomes&nbsp;and&nbsp;future&nbsp;financial&nbsp;hardships.
            &nbsp;As a CPA trained in collaborative divorce and mediation,
            Elizabeth put her many years of experience working with divorce
            attorneys/mediators and navigating these difficult emotional waters
            to work for you to help you through this stressful time in your
            life. Elizabeth can help you:
          </p>

          <ChecklistContainer>
            <div
              id="inbound-list"
              className="inbound-list class-CipeIJakWB fa-list-check"
            >
              {" "}
              <ul>
                <li>Take financial control of the divorce process</li>
                <li>
                  Gain&nbsp;a&nbsp;clear&nbsp;understanding&nbsp;of&nbsp;your
                  <strong>
                    <em> current</em>
                  </strong>{" "}
                  and&nbsp;
                  <strong>
                    <em>future</em>
                  </strong>{" "}
                  financial&nbsp;picture
                </li>
                <li>
                  Be&nbsp;more&nbsp;focused&nbsp;on&nbsp;reaching&nbsp;a&nbsp;fair&nbsp;and&nbsp;workable&nbsp;settlement
                </li>
                <li>Negotiate&nbsp;more&nbsp;effectively</li>
                <li>Reduce&nbsp;legal&nbsp;fees</li>
                <li>Minimize&nbsp;taxes&nbsp;for&nbsp;investment</li>
                <li>Maximize&nbsp;marital&nbsp;assets</li>
                <li>
                  Perform a detailed <strong>financial and tax review</strong>{" "}
                  of your proposed settlement{" "}
                  <strong>
                    <em>BEFORE</em>
                  </strong>{" "}
                  you sign your agreement
                </li>
              </ul>
            </div>
          </ChecklistContainer>
          <br />
          <h3>
            <strong>Post Divorce</strong>
          </h3>
          <p>Elizabeth can assist you with:</p>

          <ChecklistContainer>
            <div
              id="inbound-list"
              className="inbound-list class-CipeIJakWB fa-list-check"
            >
              <ul>
                <li>
                  The division of financial assets such as IRA and stock
                  accounts
                </li>
                <li>
                  Monitoring vesting and payment schedules for deferred
                  compensation (such as stock options and RSUs)
                </li>
                <li>Post-tax projections of deferred compensation</li>
                <li>
                  Monitoring income of your ex-spouse for alimony/safe harbor
                  provisions
                </li>
                <li>Post divorce financial planning</li>
                <li>Tax preparation and filing</li>
              </ul>
            </div>
          </ChecklistContainer>
        </div>
      </div>
    </PageContainerDivContent>
  );
};
