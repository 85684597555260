import { PageContainerDivContent } from "../../../components/PageContainer/styles";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { TRACKING_ID } from "../../../App";

export const InvestmentManagement = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/investment-management");
  }, []);
  return (
    <PageContainerDivContent>
      <Helmet>
        <title>Investment Management | Merit Financial Partners</title>
        <meta
          name={"description"}
          content={
            "Learn more about Merit Financial Partner's investment management service."
          }
        />
      </Helmet>
      <div className="entry-wrap">
        <div className="entry-content content">
          <p>
            You may choose to engage our firm to implement investment strategies
            recommended in your financial plan.
          </p>
          <h3>
            <strong>Our Philosophy</strong>
          </h3>
          <ul>
            <li>
              <strong>Dedicated, Personal Advisor –</strong>We pride ourselves
              on providing you personal, reliable ongoing advice on your
              investments and financial planning concerns.
            </li>
            <li>
              <strong>Objective Advice –</strong>We do not sell any financial
              products; we sell only our advice and services. This is different
              from other “advisors” in that we do not receive any commissions or
              referral fees.
            </li>
            <li>
              <strong>Tax Efficiency – </strong>Our managed portfolios use low
              cost, no load mutual funds with periodic rebalancing to achieve
              tax efficiency.
            </li>
            <li>
              <strong>Fiduciary Standard –</strong>We are committed to a
              fiduciary standard; we are bound to place clients’ interests above
              that of the firm. In addition, we adhere to the Certified
              Financial Planner Board of Stands and Ethics.
            </li>
            <li>
              <strong>Cost Effective –</strong>We find cost-effective mutual
              funds that meet the needs of each client in terms of risks, total
              return, taxes and costs. We also maintain a philosophy of
              rebalancing portfolios only when needed in order to keep
              transaction costs low.
            </li>
          </ul>
          <h3>
            <strong>Fees</strong>
          </h3>
          <p>
            We charge a fixed fee based on the size and complexity of your
            portfolio. Our standard management fee is 0.50 percent of your investment portfolio. While there is not an asset
            minimum for our services, we require a minimum fee of $3,000 per
            year, which is payable in arrears in quarterly installments of $750.
            Financial planning and investment management services can be
            combined at a discounted rate. MFP uses Charles Schwab as our
            custodian for investment management.
          </p>
          <p>
            <em>
              Merit Financial Partners, LLC has no monetary stake in the choice
              of recommendations. We do not sell investments or insurance
              products, so there are no commissions. And we neither accept nor
              pay referral fees.{" "}
            </em>
          </p>
        </div>
      </div>
    </PageContainerDivContent>
  );
};
