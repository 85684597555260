import styled from "styled-components";

export const ButtonContainer = styled.div`
  height: 70px;
  width: 100%;
  text-align: center;
`;

export const ZelleButton = styled.a`
  background: linear-gradient(to bottom, #8540d8 5%, #5f20c4 100%);
  border-radius: 10px;
  font-size: 19px;
  padding: 12px 37px;
  border: 2px solid #000000;
  color: #ffffff;
  height: 50px;
  width: 40vw;
  text-align: center;
  :hover {
    cursor: default;
    color: white;
  }
`;

export const PaySimpleButton = styled.a`
  background: linear-gradient(
    to bottom,
    var(--MERIT-BLUE) 85%,
    var(--MERIT-BLUE) 100%
  );
  border-radius: 10px;
  font-size: 19px;
  padding: 12px 37px;
  border: 2px solid #000000;
  color: #ffffff;
  height: 50px;
  width: 40vw;
  text-align: center;
`;
