import { PageContainerDivContent } from "../../components/PageContainer/styles";
import { ContentsLinks } from "./styles";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { TRACKING_ID } from "../../App";

export const Services = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/services");
  }, []);
  return (
    <PageContainerDivContent>
      <Helmet>
        <title>Services | Merit Financial Partners</title>
      </Helmet>
      <ContentsLinks href={"services-financial-planning"}>
        <li>Financial Planning Services</li>
      </ContentsLinks>
      <br />
      <ContentsLinks href={"services-divorce-planning"}>
        <li>Divorce Financial Planning</li>
      </ContentsLinks>
      <br />
      <ContentsLinks href={"services-investment-management"}>
        <li>Investment Management</li>
      </ContentsLinks>
      <br />
      <ContentsLinks href={"services-ongoing-advisory"}>
        <li>Ongoing Advisory Service</li>
      </ContentsLinks>
      <br />
      {/*<ContentsLinks href={"services-ongoing-advisory-premium"}>*/}
      {/*  <li>Premium Ongoing Advisory Service</li>*/}
      {/*</ContentsLinks>*/}
      {/*<br />*/}
      {/*<ContentsLinks href={"services-tax-advice"}>*/}
      {/*  <li>Tax Advice and Preparation</li>*/}
      {/*</ContentsLinks>*/}
      {/*<br />*/}
    </PageContainerDivContent>
  );
};
