import styled from "styled-components";
import footsteps2 from "../../../../../images/stock/beach.jpg";

export const CallToActionContainer = styled.div`
  background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.57) 0%,
      rgba(0, 0, 0, 0.46) 46%,
      rgba(25, 25, 25, 0.26) 64%,
      rgba(255, 255, 255, 0) 100%
    ),
    url(${footsteps2});
  height: 677px;
  min-height: 677px;
  width: 100%;
  min-width: 100vw;
  padding-top: 0px;
  padding-bottom: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  @media (max-width: 870px) {
    height: 455px;
  }
  @media (max-width: 760px) {
    height: 555px;
  }
`;

export const CallToActionHeader = styled.div`
  text-align: start;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-family: "arial", sans-serif;
  font-size: 32px;
  font-weight: bold;
  @media (max-width: 870px) {
    width: 100%;
    max-width: 70vw;
  }
`;

export const CTATextContainer = styled.div`
  width: 60%;
  min-width: 597px;
  height: 100%;
  row-gap: 2rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 870px) {
    width: 100%;
    max-width: 70vw;
  }
`;
export const CallToActionSubtitle = styled.div`
  text-align: start;
  color: #ffffff;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-family: "arial", sans-serif;
  font-size: 22px;
  font-style: italic;
  @media (max-width: 870px) {
    max-width: 70vw;
  }
`;
export const CallToActionButtonContainer = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1rem;
  width: 100%;
`;
export const PaymentActionButton = styled.button<{ formatDisabled?: boolean }>`
  height: 40px;
  width: 400px;
  font-family: "Arial", sans-serif;
  font-size: 18px;
  text-decoration: underline;
  font-weight: bold;
  background-color: ${(props) =>
    props.formatDisabled ? "var(--GLOBAL-GRAY)" : "var(--MERIT-BLUE)"};
  cursor: ${(props) =>
    props.formatDisabled ? "not-allowed" : "pointer"} !important;
  color: ${(props) => (props.formatDisabled ? "lightgray" : "white")};
  opacity: ${(props) => (props.formatDisabled ? 0.7 : undefined)};
  margin-left: 20px;
  margin-right: 20px;
  border: none;
  margin-bottom: 0.25em;
  text-shadow: 0 0.075em 0.075em rgba(0, 0, 0, 0.5);
  box-shadow:
    0 0.25em 0 0 #232323,
    0 4px 9px rgba(0, 0, 0, 0.75);
  border-radius: 0.25em;
  padding: 0.385em 0.923em 0.538em;

  :hover {
    background-color: var(--MERIT-BLUE);
    color: var(--GLOBAL-GRAY);
  }

  :disabled {
    cursor: not-allowed;
    background-color: var(--GLOBAL-LIGHTGRAY);
  }
`;
export const CallToActionButton = styled.button`
  height: 60px;
  width: 200px;
  font-family: "Arial", sans-serif;
  font-size: 22px;
  text-decoration: underline;
  font-weight: bold;
  background-color: var(--GLOBAL-GRAY);
  border: none;
  color: white;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
  border-color: #3a3a3a;
  margin-bottom: 0.25em;
  text-shadow: 0 0.075em 0.075em rgba(0, 0, 0, 0.5);
  box-shadow:
    0 0.25em 0 0 #232323,
    0 4px 9px rgba(0, 0, 0, 0.75);
  border-radius: 0.25em;
  padding: 0.385em 0.923em 0.538em;

  :hover {
    background-color: var(--GLOBAL-LIGHTGRAY);
    color: var(--GLOBAL-GRAY);
  }
`;
