import {
  ListItem,
  PageContainerDivContent,
  UnorderedList,
} from "../../../components/PageContainer/styles";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { TRACKING_ID } from "../../../App";

const htmlString = "<h1>Hello World</h1>";

export const CollegePlanning = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/college-planning");
  }, []);
  return (
    <PageContainerDivContent>
      <Helmet>
        <title>College Planning | Merit Financial Partners</title>
        <meta
          name={"description"}
          content={
            "The cost of college has skyrocketed over the last 25 years and it is not going to get any better. We can help parents of high school students manage the admissions and financial aid process."
          }
        />
      </Helmet>
      <table>
        <tr>
          <td style={{ width: "80%" }}>
            <div className="entry-content content">
              <p>
                The cost of college has skyrocketed over the last 25 years and
                it is not going to get any better. We can help parents of high
                school students manage the admissions and financial aid process.
                We focus on your most important issues in the process, such as:
              </p>
              <UnorderedList>
                <ListItem>
                  <strong>Finding the right college/program</strong>
                </ListItem>
                <ListItem>
                  <strong>Enhancing your child’s chance of admission</strong>
                </ListItem>
                <ListItem>
                  <strong>Managing financial aid expectation</strong>
                </ListItem>
                <ListItem>
                  <strong>Understanding the financial aid process</strong>
                </ListItem>
                <ListItem>
                  <strong>
                    Evaluation of your expected family contribution
                  </strong>
                </ListItem>
                <ListItem>
                  <strong>
                    Filling out and/or checking the FAFSA (Free Application for
                    Student Aid)
                  </strong>
                </ListItem>
                <ListItem>
                  <strong>Filling out and/or checking the CSS Profile</strong>
                </ListItem>
                <ListItem>
                  <strong>Handling the appeal process (if any)</strong>
                </ListItem>
              </UnorderedList>

              <hr />
              <h4>Price:</h4>
              <UnorderedList>
                <ListItem>
                  <strong>Simple Package:</strong> One hour explaining the
                  process and strategies for picking the right school to
                  maximize your financial aid offer. $350
                </ListItem>
                <ListItem>
                  <strong>Enhanced Package:</strong> Includes points one through
                  4 above and checking the FAFSA and CSS PROFILE: $1000
                </ListItem>
                <ListItem>
                  <strong>Comprehensive Package:</strong> Includes all of the
                  above plus filling out the FAFSA and Profile: $1,500.
                </ListItem>
              </UnorderedList>

              <p>&nbsp;</p>
            </div>
          </td>
          <td style={{ width: "20%", alignContent: "top" }}>
            <iframe
              style={{ height: "600px" }}
              src={"https://merit-public.s3.amazonaws.com/pages/signup.html"}
            />
          </td>
        </tr>
      </table>
      <iframe
        style={{ height: "600px", width: "100%" }}
        src={
          "https://merit-public.s3.amazonaws.com/pages/dynamicEmailBoth-meritfinancialpartners.html"
        }
      />
    </PageContainerDivContent>
  );
};
