import { PageContainerDivContent } from "../../../components/PageContainer/styles";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { TRACKING_ID } from "../../../App";

export const AssetAllocationPortfolioReview = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/asset-allocation");
  }, []);
  return (
    <PageContainerDivContent>
      <Helmet>
        <title>
          Asset Allocation Portfolio Review | Merit Financial Partners
        </title>
        <meta
          name={"description"}
          content={
            "This package provides a thorough analysis of your Investment Portfolio, Asset Allocation, short- and long-term objectives and risk review. It is designed both for those looking to evaluate the advice of their current investment advisors, as well as those interested in a third-party evaluation of their unadvised portfolios."
          }
        />
      </Helmet>
      <div className="x-container max width offset">
        <div className="x-main full" role="main">
          <article
            id="post-258"
            className="post-258 page type-page status-publish hentry no-post-thumbnail"
          >
            <div className="entry-wrap">
              <div className="entry-content content">
                <p>
                  This package provides a thorough analysis of your Investment
                  Portfolio, Asset Allocation, short- and long-term objectives
                  and risk review.
                </p>
                <p>
                  It is designed both for those looking to evaluate the advice
                  of their current investment advisors, as well as those
                  interested in a third-party evaluation of their unadvised
                  portfolios.
                </p>
                <p>Price range: $800-$1200</p>
              </div>
            </div>
          </article>
        </div>
      </div>
    </PageContainerDivContent>
  );
};
