import styled from "styled-components";

export const MainContentTableContainer = styled.div`
  padding-left: 4%;
  padding-right: 4%;
  background-color: var(--WHITE);
  opacity: 1;
`;

export const MainContentTable = styled.table`
  color: white;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  @media (max-width: 870px) {
    padding-top: 0;
    padding-bottom: 0;
  }
  text-align: left;
`;
export const MainContentTableRow = styled.tr`
  width: 50%;

  @media (max-width: 870px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const MainContentTableData = styled.td`
  vertical-align: top;
  font-family: "Arial", sans-serif;
  padding: 10px;
  width: 50%;
  padding-bottom: 50px;
  color: #2b3137;
  font-size: 18px;

  @media (max-width: 870px) {
    padding-bottom: 0;
    padding-top: 0;
    width: 100%;
  }
`;

export const H1 = styled.h1`
  font-family: "Arial", sans-serif;
  //text-shadow:
  //  1px 1px 1px #2e353c,
  //  0.1px 0.1px 0.1px #2e353c,
  //  0.1px 0.1px 0.1px #2e353c,
  //  0.1px 0.1px 0.1px #2e353c,
  //  0.1px 0.1px 0.1px #2e353c;
  //0 6px 1px rgba(0, 0, 0, 0.1),
  //0 0 5px rgba(0, 0, 0, 0.1),
  //0 1px 3px rgba(0, 0, 0, 0.3),
  //0 3px 5px rgba(0, 0, 0, 0.2),
  //0 5px 10px rgba(0, 0, 0, 0.25),
  //0 10px 10px rgba(0, 0, 0, 0.2),
  //0 20px 20px rgba(0, 0, 0, 0.15);
  font-weight: 600;
  text-align: center;
  font-size: 42px;
  padding-top: 20px;
  color: #2b3137;
`;
export const H7 = styled.div`
  font-family: "Arial", sans-serif;
  text-shadow:
    0 0.5px 0 #ccc,
    0 1px 0 #c9c9c9,
    0 1px 0 #bbb,
    0 1px 0 #b9b9b9,
    0 3px 0 #aaa,
    0 2px 1px rgba(0, 0, 0, 0.1),
    0 0 2px rgba(0, 0, 0, 0.1);
  font-weight: 200;
  text-align: center;
  font-size: 18px;
  padding-top: 30%;
  vertical-align: bottom;
`;
