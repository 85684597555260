import { PageContainerDivContent } from "../../../components/PageContainer/styles";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { TRACKING_ID } from "../../../App";

export const RetirementRoadMap = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/retirement-road-map");
  }, []);
  return (
    <PageContainerDivContent>
      <Helmet>
        <title>Retirement Road Map | Merit Financial Partners</title>
        <meta
          name={"description"}
          content={
            "Do you want to know what you need to do to retire when you want, how you want? Our retirement planning process is for those planning to retire within 5-15 years. This option provides an in-depth review of your current financial standing and your retirement objectives."
          }
        />
      </Helmet>
      <div className="x-container max width offset">
        <div className="x-main full" role="main">
          <article
            id="post-156"
            className="post-156 page type-page status-publish hentry no-post-thumbnail"
          >
            <div className="entry-wrap">
              <div className="entry-content content">
                <p>
                  Do you want to know what you need to do to retire when you
                  want, how you want? Our retirement planning process is for
                  those planning to retire within 5-15 years. This option
                  provides an in-depth review of your current financial standing
                  and your retirement objectives. The process includes:
                </p>
                <ul>
                  <li>
                    <strong>Current net worth statement</strong>
                    <ul>
                      <li>Overall picture of your assets and debts</li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Current cash flow statement</strong>
                    <ul>
                      <li>
                        Where is your money going and how much should you be
                        saving?
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>
                      Retirement capital needs analysis; up to 2 scenarios
                    </strong>
                    <ul>
                      <li>
                        Do you need to make adjustments to retire the way you
                        want? Are you putting aside enough savings? At what age
                        can you retire?
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>
                      Review of your current asset allocation and investment
                      portfolio{" "}
                    </strong>
                    <ul>
                      <li>
                        Is your current asset allocation appropriate for your
                        investment objectives? Is it tax efficient? Are you
                        paying too much in fees?
                      </li>
                    </ul>
                  </li>
                </ul>
                <p>Price range: $1,900-$3,500</p>
              </div>
            </div>
          </article>
        </div>
      </div>
    </PageContainerDivContent>
  );
};
