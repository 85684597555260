import { PageContainerDivContent } from "../../components/PageContainer/styles";
import { ContentsLinks } from "../Services/styles";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { TRACKING_ID } from "../../App";

export const FinancialPlanningPackages = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/financial-planning-packages");
  }, []);
  return (
    <PageContainerDivContent>
      <Helmet>
        <title>Financial Planning Packages | Merit Financial Partners</title>
        <meta
          name={"description"}
          content={
            "Learn more about Merit Financial Partners' financial planning packages."
          }
        />
      </Helmet>
      <ContentsLinks href={"packages-college"}>
        <li>College Planning</li>
      </ContentsLinks>
      <br />
      <ContentsLinks href={"comprehensive-financial-plan"}>
        <li>Comprehensive Financial Plan</li>
      </ContentsLinks>
      <br />
      <ContentsLinks href={"retirement-road-map"}>
        <li>Retirement Road Map</li>
      </ContentsLinks>
      <br />
      <ContentsLinks href={"packages-bottom-line"}>
        <li>The Bottom Line Planning Package</li>
      </ContentsLinks>
      <br />
      {/*<ContentsLinks href={"packages-asset-allocation"}>*/}
      {/*  <li>Asset Allocation/Portfolio Review</li>*/}
      {/*</ContentsLinks>*/}
    </PageContainerDivContent>
  );
};
