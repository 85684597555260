import { PageContainerDivContent } from "../../../components/PageContainer/styles";
import { ChecklistContainer } from "../styles";
import { PaymentActionButton } from "../../Home/components/CallToAction/styles";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { TRACKING_ID } from "../../../App";

export const OngoingAdvisoryService = () => {
  const [checked, setChecked] = useState(false);
  const [highlightCheckbox, setHighlightCheckbox] = useState(false);
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/ongoing-advisory-service");
  }, []);
  return (
    <PageContainerDivContent>
      <Helmet>
        <title>Ongoing Advisory Service | Merit Financial Partners</title>
        <meta
          name={"description"}
          content={
            "Learn more about Merit Financial Partner's ongoing advisory service."
          }
        />
      </Helmet>
      <div className="entry-content content">
        <h3>A Collaborative Approach</h3>
        <p>
          After we have completed a financial plan together, you have the option
          to continue with Merit Financial Partners on an ongoing basis.
        </p>
        <h4>Ongoing Advisory Service Includes:</h4>
        <ChecklistContainer>
          <ul>
            <li>
              An annual review meeting to update your financial plan and review
              your asset allocation.
            </li>
            <li>
              Financial advice on an ad hoc basis via phone calls and emails.
            </li>
            <li>
              Help when you need it, whether it’s buying a car or refinancing
              your mortgage. You can contact us whenever you have a question
              related to your personal finances. No extra charge!
            </li>
            <li>Quarterly investment/market newsletter.</li>
            <li>
              Access to a secure client portal where your financial plan is
              safely stored and accessible. You can also upload other important
              financial, estate and tax documents to your secure portal which
              you can electronically access at any time.
            </li>
          </ul>
        </ChecklistContainer>
        <strong>Monthly Subscription Fee: $150</strong>
        <br />
        <br />
        <div
          onClick={() => {
            setChecked(!checked);
            setHighlightCheckbox(false);
          }}
          style={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
            columnGap: "0.25rem",
            cursor: "pointer",
            alignItems: "center",
          }}
        >
          <input type="checkbox" checked={checked} />{" "}
          <p style={{ margin: "0 0" }}>
            I agree to be charged $150/month for standard ongoing advisory
            services as described above.
          </p>
        </div>
        <div style={{ height: "30px" }}>
          {highlightCheckbox && (
            <p style={{ margin: "0 0", color: "red" }}>
              *Please confirm the payment terms to continue
            </p>
          )}
        </div>
        <div style={{ width: "100%;", textAlign: "center" }}>
          <PaymentActionButton
            formatDisabled={!checked}
            name=""
            type="button"
            value="Ongoing Advisory Service Sign Up"
            onClick={() => {
              if (!checked) {
                setHighlightCheckbox(true);
                return;
              }
              window.open(
                "https://meritfinancialpartners.mypaysimple.com/s/standard-investment-advisory-service",
              );
            }}
          >
            Ongoing Advisory Service Sign Up
          </PaymentActionButton>
        </div>
        <hr />
        <p>
          <i>
            Payment for this ongoing service will be charged to your
            credit/debit card every month.
          </i>
        </p>
        <p>
          <i>
            Please note that these services do not include implementation of
            investment advice. You control and rebalance your accounts. If you
            prefer investment management, this would be a separate service.
          </i>
        </p>
        <p>
          <i>
            Merit Financial Partners, LLC has no monetary stake in the choice of
            recommendations. We do not sell investments or insurance products,
            so there are no commissions. And we neither accept nor pay referral
            fees. In the parlance of the industry, we provide advice on an
            hourly, fee-only basis.
          </i>
        </p>
      </div>
    </PageContainerDivContent>
  );
};
