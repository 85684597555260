import styled from "styled-components";

export const ContentsLinks = styled.a`
  font-size: x-large;
  font-weight: 500;
  line-height: 1;
  list-style-type: square;
  color: var(--MERIT-BLUE);
  :visited {
    color: rebeccapurple;
  }
`;

export const ChecklistContainer = styled.div`
  #inbound-list.class-CipeIJakWB li {
    color: black; //#0886c5;
    list-style: circle;
    //font-weight: 500;
    font-size: 16px;
    vertical-align: top;
    margin-bottom: 10px;
  }
  #inbound-list.class-CipeIJakWB li:before {
    background: transparent;
    border-radius: 50% 50% 50% 50%;
    color: #5dac56;
    display: inline-block;
    font-size: 18px;
    line-height: 18px;
    margin-right: 0.5em;
    margin-top: 0;
    text-align: center;
  }

  @media only screen and (max-width: 580px) {
    #inbound list.class li {
      width: 100%;
    }
  }
  p:empty {
    display: none;
  }
`;
