import styled from "styled-components";

export const AboutTable = styled.table`
  width: 100%;
`;

export const AboutTableRow = styled.tr`
  width: 100%;
`;

export const AboutTableData = styled.td`
  width: 50%;
`;

export const AboutImageContainer = styled.div`
  text-align: center;
  align-content: center;
  width: 100%;
`;
export const AboutImage = styled.img`
  text-align: center;
  align-content: center;
  width: 250px;
`;
export const AboutImageText = styled.div`
  padding-top: 10px;
  text-align: center;
  align-content: center;
`;
