import styled from "styled-components";
import { FOOTER_CONTAINER_HEIGHT } from "../../../constants";

export const FooterContainer = styled.div`
  background-color: var(--GLOBAL-DARKBLUE);
  color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 4%;
  padding-right: 4%;
  font-family: "Arial", sans-serif;
  font-size: 14px;
  text-rendering: optimizelegibility;
  height: ${FOOTER_CONTAINER_HEIGHT};
`;

export const FooterHeaderContainer = styled.div`
  color: white;
  text-align: center;
  font-size: 18px;
  display: flex;
  line-height: 1;
  font-family: "Arial", sans-serif;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
  padding: 10px 0;
`;
