import { PageContainerDivContent } from "../../components/PageContainer/styles";
import { ContentsLinks } from "../Services/styles";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { TRACKING_ID } from "../../App";

export const About = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/about");
  }, []);
  return (
    <PageContainerDivContent>
      <Helmet>
        <title>About | Merit Financial Partners</title>
        <meta
          name={"description"}
          content={"Learn more about our team at Merit Financial Partners."}
        />
      </Helmet>
      <ContentsLinks href={"michael-terry"}>
        <li>Michael Terry CFP®</li>
      </ContentsLinks>
      <br />
      <ContentsLinks href={"elizabeth-cox"}>
        <li>Elizabeth Cox CFP®, CPA</li>
      </ContentsLinks>
      <br />
      <ContentsLinks href={"hrefna-martin"}>
        <li>Hrefna Martin CFP®, EA</li>
      </ContentsLinks>
      <br />
      <ContentsLinks href={"melissa-adams"}>
        <li>Melissa Adams CFP®</li>
      </ContentsLinks>
      <br />
      <ContentsLinks href={"ed-esposito"}>
        <li>Ed Esposito CFP®, EA</li>
      </ContentsLinks>
    </PageContainerDivContent>
  );
};
