import { PageContainerDivContent } from "../../components/PageContainer/styles";
import { ContentsLinks } from "../Services/styles";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { TRACKING_ID } from "../../App";

export const Payment = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/payment");
  }, []);
  return (
    <PageContainerDivContent>
      <Helmet>
        <title>Payment | Merit Financial Partners</title>
        <meta
          name={"description"}
          content={
            "Pay for an invoice or sign up for a service with Merit Financial Partners."
          }
        />
      </Helmet>
      <ContentsLinks href={"pay-now"}>
        <li>Pay Now</li>
      </ContentsLinks>
      <br />
      <ContentsLinks href={"services-ongoing-advisory"}>
        <li>Ongoing Advisory Service Sign Up</li>
      </ContentsLinks>
      <br />
      {/*<ContentsLinks href={"services-ongoing-advisory-premium"}>*/}
      {/*  <li>Premium Ongoing Advisory Service Sign Up</li>*/}
      {/*</ContentsLinks>*/}
    </PageContainerDivContent>
  );
};
