import { PageContainerDivContent } from "../../../components/PageContainer/styles";
import hrefna from "../../../images/about/hrefnamartin.jpg";
import {
  AboutImage,
  AboutImageContainer,
  AboutImageText,
  AboutTable,
  AboutTableData,
  AboutTableRow,
} from "../styles";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { TRACKING_ID } from "../../../App";

export const HrefnaMartin = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/hrefna-martin");
  }, []);
  return (
    <PageContainerDivContent>
      <Helmet>
        <title>Hrefna Martin | Merit Financial Partners</title>
        <meta
          name={"description"}
          content={
            "Hrefna Martin is a Certified Financial Planner and Registered Investment Advisor Agent with Merit Financial Partners, LLC (MFP).  She works with clients either on an as needed hourly basis, helping them with specific financial planning needs or on an ongoing basis, helping clients implement and monitor their financial plans and manage or advise on their investments."
          }
        />
      </Helmet>
      <p>
        Hrefna Martin is a Certified Financial Planner and Registered Investment
        Advisor Agent with Merit Financial Partners, LLC (MFP). &nbsp;She works
        with clients either on an as needed hourly basis, helping them with
        specific financial planning needs or on an ongoing basis, helping
        clients implement and monitor their financial plans and manage or advise
        on their investments.
      </p>
      <p>
        Hrefna has over 14 years of experience in Financial Planning and
        Investment Management. &nbsp;She worked as an Investment Advisor at Cox
        Financial Services, predecessor to MFP, in Westport CT from 2015 to
        2017, as a Financial Counselor at Resnick Advisors in Westport CT from
        2011 to 2015 and as an Investment Associate at Novos Planning Associates
        in New York NY from 2003 to 2011.
      </p>
      <AboutTable>
        <AboutTableRow>
          <AboutTableData>
            <strong>
              My professional and academic qualifications include:
            </strong>
            <ul>
              <li>CERTIFIED FINANCIAL PLANNER™ certification (CFP)</li>
              <li>
                Diploma in Financial Planning, College for Financial Planning
              </li>
              <li>Registered Investment Advisor Agent</li>
              <li>Enrolled Agent (EA) certification</li>
              <li>MBA, Financial Management, Pace University, New York, NY</li>
              <li>B.Sc., University of Iceland, Reykjavik, Iceland</li>
            </ul>
            <p>
              <strong>I am a member of the following organizations:</strong>
            </p>
            <ul>
              <li>
                <a href="http://www.napfa.org/">
                  National Association of Personal Financial Advisors (NAPFA)
                </a>
              </li>
              <li>
                <a href="http://www.epclfc.org">
                  Estate Planning Council of Lower Fairfield County
                </a>
              </li>
            </ul>
          </AboutTableData>
          <AboutTableData>
            <AboutImageContainer>
              <AboutImage src={hrefna} />
              <AboutImageText>
                <h4>Hrefna S. Martin</h4>
                CFP®, EA
                <br />
                <a href="mailto:hmartin@meritfp.com">Email Me</a>
              </AboutImageText>
            </AboutImageContainer>
          </AboutTableData>
        </AboutTableRow>
      </AboutTable>
    </PageContainerDivContent>
  );
};
