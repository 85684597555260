import { PageContainerDivContent } from "../../../components/PageContainer/styles";
import { ChecklistContainer } from "../styles";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { TRACKING_ID } from "../../../App";
export const FinancialPlanningServices = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/financial-planning-services");
  }, []);
  return (
    <PageContainerDivContent>
      <Helmet>
        <title>Financial Planning Services | Merit Financial Partners</title>
        <meta
          name={"description"}
          content={
            "Merit Financial Partners has the experience and competence to guide you toward whatever financial goals you have, like planning your retirement, buying a new home, affording college, or minimizing your tax burden."
          }
        />
      </Helmet>
      <div className="x-container max width offset">
        <div className="x-main full" role="main">
          <article
            id="post-193"
            className="post-193 page type-page status-publish hentry no-post-thumbnail"
          >
            <div className="entry-wrap">
              <div className="entry-content content">
                <p>
                  Merit Financial Partners has the experience and competence to
                  guide you toward whatever financial goals you have, like
                  planning your retirement, buying a new home, affording
                  college, or minimizing your tax burden. Our services include:
                </p>
                <ChecklistContainer>
                  <div
                    id="inbound-list"
                    className="inbound-list class-CipeIJakWB fa-list-check"
                  >
                    <ul>
                      <li>Spending Versus Saving Decisions (Budgeting)</li>
                      <li>Divorce Financial Planning</li>
                      <li>Retirement Income Planning</li>
                      <li>Portfolio Distribution Planning</li>
                      <li>Investment Advice</li>
                      <li>Second Opinion on a Current Portfolio</li>
                      <li>Insurance Needs Assessment and Policy Analysis</li>
                      <li>College Planning</li>
                      <li>Tax Planning</li>
                      <li>Estate and Gift Planning</li>
                    </ul>
                  </div>
                </ChecklistContainer>
                <p>
                  You can either choose one of our financial planning packages
                  or engage us on an hourly basis at our hourly rate of $350/hr
                  without any longer term commitment. We are one of the very few
                  financial planning firms in the Fairfield County area that
                  provides this much-needed unbiased, service.
                </p>
                <p>
                  Please{" "}
                  <a title="Contact Us" href="/contact/">
                    <strong>
                      <span style={{ textDecoration: "underline" }}>
                        contact us
                      </span>
                    </strong>
                  </a>{" "}
                  to set up an initial consultation.
                </p>
              </div>
            </div>
          </article>
        </div>
      </div>
    </PageContainerDivContent>
  );
};
