import React from "react";
import cfp_logo from "../../../../images/stock/Logo-CFP.png";
import aicpa_logo from "../../../../images/stock/AICPA.png";
import napfa_logo from "../../../../images/stock/NAPFA.png";
import cpa_logo from "../../../../images/stock/CPA.gif";

export const LogoTicker = () => {
  const images = [cfp_logo, aicpa_logo, napfa_logo, cpa_logo];
  return (
    // <Ticker duration={40}>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        alignContent: "center",
      }}
    >
      {images.map((item, index) => (
        <div
          key={index}
          style={{
            // margin: "5px",
            // height: "100px",
            // minWidth: `${100 / images.length}vw`,
            height: "74px",
            // margin: "0 15%",
            display: "flex",
            width: "20%",
            justifyContent: "center",
          }}
        >
          <img
            key={index}
            src={item}
            style={{
              objectFit: "contain",
              width: "fit-content",
              overflow: "hidden",
              display: "flex",
            }}
          />
        </div>
      ))}
    </div>
    // </Ticker>
  );
};
