import React, { useState } from "react"; // import "@szhsin/react-menu/dist/index.css";
// import "@szhsin/react-menu/dist/transitions/slide.css";
import "./styles";
import "./styles/styles.css";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { NAVBAR_HEIGHT } from "../../constants";
import styled from "styled-components";

const NavBarContainer = styled.div`
  height: ${NAVBAR_HEIGHT}px;
  @media (max-width: 760px) {
    height: 55px;
  }
  overflow: visible;
  z-index: 4;
`;
export const MenuBar = () => {
  const [portalDropdownSelected, setPortalDropdownSelected] = useState(false);
  const [fpServicesDropdownSelected, setFPServicesDropdownSelected] =
    useState(false);
  const [servicesDropdownSelected, setServicesDropdownSelected] =
    useState(false);
  const [getStartedDropdownSelected, setGetStartedDropdownSelected] =
    useState(false);
  const [aboutDropdownSelected, setAboutDropdownSelected] = useState(false);
  const [payNowDropdownSelected, setPayNowDropdownSelected] = useState(false);

  const clearDropdownSelections = () => {
    setPortalDropdownSelected(false);
    setFPServicesDropdownSelected(false);
    setServicesDropdownSelected(false);
    setGetStartedDropdownSelected(false);
    setAboutDropdownSelected(false);
    setPayNowDropdownSelected(false);
  };

  return (
    <NavBarContainer>
      <Navbar bg="color-nav" expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                href="/"
                onClick={() => {
                  clearDropdownSelections();
                }}
              >
                Home
              </Nav.Link>
              <NavDropdown
                title="Portal"
                id="portal"
                show={portalDropdownSelected}
                onMouseEnter={() => {
                  setPortalDropdownSelected(true);
                }}
                onMouseLeave={() => {
                  setPortalDropdownSelected(false);
                }}
              >
                <NavDropdown.Item
                  href="https://main.yhlsoft.com/auth/users/webportal/Merit"
                  target="_default"
                >
                  Client Login
                </NavDropdown.Item>
                {/*<NavDropdown.Item*/}
                {/*  href="https://main.yhlsoft.com/auth/users/register?key=Merit"*/}
                {/*  target="_default"*/}
                {/*>*/}
                {/*  Client Registration*/}
                {/*</NavDropdown.Item>*/}
              </NavDropdown>
              <NavDropdown
                show={fpServicesDropdownSelected}
                onMouseEnter={() => {
                  setFPServicesDropdownSelected(true);
                }}
                onMouseLeave={() => {
                  setFPServicesDropdownSelected(false);
                }}
                onClick={() => {
                  window.open(
                    process.env.PUBLIC_URL + "/financial-planning-packages",
                    "_self",
                  );
                }}
                title="Financial Planning Packages"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  href={
                    process.env.PUBLIC_URL + "/comprehensive-financial-plan"
                  }
                  onClick={() => {
                    clearDropdownSelections();
                  }}
                >
                  Comprehensive Financial Plan
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={process.env.PUBLIC_URL + "/retirement-road-map"}
                  onClick={() => {
                    clearDropdownSelections();
                  }}
                >
                  Retirement Road Map
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={process.env.PUBLIC_URL + "/packages-bottom-line"}
                  onClick={() => {
                    clearDropdownSelections();
                  }}
                >
                  The Bottom Line Planning Package
                </NavDropdown.Item>
                {/*<NavDropdown.Item*/}
                {/*  href={process.env.PUBLIC_URL + "/packages-asset-allocation"}*/}
                {/*  onClick={() => {*/}
                {/*    clearDropdownSelections();*/}
                {/*    //setPackagesSelected(true);*/}
                {/*  }}*/}
                {/*>*/}
                {/*  Asset Allocation/Portfolio Review*/}
                {/*</NavDropdown.Item>*/}
                <NavDropdown.Item
                  href={process.env.PUBLIC_URL + "/packages-college"}
                  onClick={() => {
                    clearDropdownSelections();
                  }}
                >
                  College Planning
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                show={servicesDropdownSelected}
                onMouseEnter={() => {
                  setServicesDropdownSelected(true);
                }}
                onMouseLeave={() => {
                  setServicesDropdownSelected(false);
                }}
                onClick={() => {
                  window.open(process.env.PUBLIC_URL + "/services", "_self");
                }}
                title="Services"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  href={
                    process.env.PUBLIC_URL + "/services-investment-management"
                  }
                  onClick={() => {
                    clearDropdownSelections();
                  }}
                >
                  Investment Management
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={process.env.PUBLIC_URL + "/services-financial-planning"}
                  onClick={() => {
                    clearDropdownSelections();
                  }}
                >
                  Financial Planning Services
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={process.env.PUBLIC_URL + "/services-divorce-planning"}
                  onClick={() => {
                    clearDropdownSelections();
                  }}
                >
                  Divorce Financial Planning
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={process.env.PUBLIC_URL + "/services-ongoing-advisory"}
                  onClick={() => {
                    clearDropdownSelections();
                  }}
                >
                  Ongoing Advisory Service
                </NavDropdown.Item>
                {/*<NavDropdown.Item*/}
                {/*  href={*/}
                {/*    process.env.PUBLIC_URL +*/}
                {/*    "/services-ongoing-advisory-premium"*/}
                {/*  }*/}
                {/*  onClick={() => {*/}
                {/*    clearDropdownSelections();*/}
                {/*  }}*/}
                {/*>*/}
                {/*  Premium Ongoing Advisory Service*/}
                {/*</NavDropdown.Item>*/}
              </NavDropdown>
              <NavDropdown
                show={getStartedDropdownSelected}
                onMouseEnter={() => {
                  setGetStartedDropdownSelected(true);
                }}
                onMouseLeave={() => {
                  setGetStartedDropdownSelected(false);
                }}
                title="Get Started"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  // href="https://www.cognitoforms.com/MeritFinancialPartnersLLC/MeritFinancialPartnersLLCConfidentialQuestionnaire"
                  href={
                    "https://main.yhlsoft.com/auth/users/register?key=Merit"
                  }
                  target="_blank"
                >
                  Confidential Questionnaire
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={process.env.REACT_APP_S3 + "/Document-check-list.pdf"}
                  target="_blank"
                >
                  Document Check List
                </NavDropdown.Item>
                {/*<NavDropdown.Item*/}
                {/*  href={*/}
                {/*    process.env.PUBLIC_URL +*/}
                {/*    "/documents/MFP-Confidential-Questionnaire-1-1.pdf"*/}
                {/*  }*/}
                {/*  target="_blank"*/}
                {/*>*/}
                {/*  Confidential Questionnaire (PDF)*/}
                {/*</NavDropdown.Item>*/}
                {/*<NavDropdown.Item*/}
                {/*  href={*/}
                {/*    process.env.PUBLIC_URL + "/documents/Risk-Questionnaire.pdf"*/}
                {/*  }*/}
                {/*  target="_blank"*/}
                {/*>*/}
                {/*  Risk Profile Questionnaire*/}
                {/*</NavDropdown.Item>*/}
                <NavDropdown.Item
                  href={
                    process.env.REACT_APP_S3 +
                    "/MFP+ADV+Part+2+-+Amended+May+2024.pdf"
                  }
                  target="_blank"
                >
                  ADV2 Brochure
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={process.env.REACT_APP_S3 + "/CRS_2022.pdf"}
                  target="_blank"
                >
                  Customer Relationship Summary
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={process.env.REACT_APP_S3 + "/Privacy-Statement.pdf"}
                  target="_blank"
                >
                  Privacy Policy Statement
                </NavDropdown.Item>
                {/*<NavDropdown.Item*/}
                {/*  href={*/}
                {/*    process.env.PUBLIC_URL +*/}
                {/*    "/documents/CFP-Code-of-Ethics-and-Professional-Responsibility.pdf"*/}
                {/*  }*/}
                {/*  target="_blank"*/}
                {/*>*/}
                {/*  CFP Code of Ethics and Professional Responsibility*/}
                {/*</NavDropdown.Item>*/}
              </NavDropdown>
              <NavDropdown
                title="About"
                id="basic-nav-dropdown"
                show={aboutDropdownSelected}
                onMouseEnter={() => {
                  setAboutDropdownSelected(true);
                }}
                onMouseLeave={() => {
                  setAboutDropdownSelected(false);
                }}
                onClick={() => {
                  window.open(process.env.PUBLIC_URL + "/about", "_self");
                }}
              >
                <NavDropdown.Item
                  href={process.env.PUBLIC_URL + "/michael-terry"}
                >
                  Michael Terry, CFP®
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={process.env.PUBLIC_URL + "/elizabeth-cox"}
                >
                  Elizabeth Cox, CPA, CFP®
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={process.env.PUBLIC_URL + "/hrefna-martin"}
                >
                  Hrefna S. Martin, CFP®, EA
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={process.env.PUBLIC_URL + "/melissa-adams"}
                >
                  Melissa Adams, CFP®
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={process.env.PUBLIC_URL + "/ed-esposito"}
                >
                  Ed Esposito, CFP®, EA
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Pay Now"
                id="basic-nav-dropdown"
                show={payNowDropdownSelected}
                onClick={() => {
                  window.open("/pay", "_self");
                }}
                onMouseEnter={() => {
                  setPayNowDropdownSelected(true);
                }}
                onMouseLeave={() => {
                  setPayNowDropdownSelected(false);
                }}
              >
                <NavDropdown.Item href={process.env.PUBLIC_URL + "/pay-now"}>
                  Pay Now
                </NavDropdown.Item>

                <NavDropdown.Item
                  href={process.env.PUBLIC_URL + "/services-ongoing-advisory"}
                >
                  Ongoing Advisory Service Sign Up
                </NavDropdown.Item>
                {/*<NavDropdown.Item*/}
                {/*  href={*/}
                {/*    process.env.PUBLIC_URL +*/}
                {/*    "/services-ongoing-advisory-premium"*/}
                {/*  }*/}
                {/*>*/}
                {/*  Premium Ongoing Advisory Service Sign Up*/}
                {/*</NavDropdown.Item>*/}
              </NavDropdown>
              <Nav.Link href={process.env.PUBLIC_URL + "/contact"}>
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </NavBarContainer>
  );
};
