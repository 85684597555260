import styled from "styled-components";
import {
  FOOTER_CONTAINER_HEIGHT,
  NAVBAR_HEIGHT,
  TOP_LOGO_HEIGHT,
} from "./constants";

export const MainRouteContainerDiv = styled.div`
  min-height: calc(
    100vh - ${TOP_LOGO_HEIGHT} - ${NAVBAR_HEIGHT} - ${FOOTER_CONTAINER_HEIGHT}
  );
  background-color: white;
`;
