import { PageContainerDivContent } from "../../components/PageContainer/styles";
import emailjs from "emailjs-com";
import React, { useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { TRACKING_ID } from "../../App";

export const Contact = () => {
  const form = useRef();
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/contact");
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_qzm102i",
        "template_ecieckp",
        form.current,
        "user_o6i0ZeCepGENWv5ZgnibL",
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        },
      );
    e.target.reset();
  };

  return (
    <PageContainerDivContent>
      <Helmet>
        <title>Contact | Merit Financial Partners</title>
        <meta
          name={"description"}
          content={
            "Thank you for your interest. We look forward to speaking with you soon. Please contact us via phone at: (203) 221-2799 (CT); (718) 898-0871 (NY); via email info@meritfp.com"
          }
        />
      </Helmet>
      <br />
      <h4>Our Locations</h4>
      <br />
      <table width="100%">
        <tr>
          <td width="50%">
            <h5 style={{ textAlign: "center", width: "100%" }}>
              500 Post Road East
              <br />
              Suite 228
              <br />
              Westport, CT 06880
            </h5>
            <h5 style={{ textAlign: "center", width: "100%" }}>
              <a href="tel:+2032212799">(203) 221-2799</a>
            </h5>
            <iframe
              style={{ border: "0" }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3004.7758715404625!2d-73.35051098444684!3d41.13941847928766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e81b74bb48864b%3A0xbca468406a5e91e4!2s500%20Post%20Rd%20E%2C%20Westport%2C%20CT%2006880!5e0!3m2!1sen!2sus!4v1634064842199!5m2!1sen!2sus"
              width="100%"
              height="300"
            >
              <br />
            </iframe>
          </td>
          <td width="50%">
            <h5 style={{ textAlign: "center" }}>
              69-64 Grand Avenue
              <br />
              Maspeth, NY 11378
            </h5>
            <h5 style={{ textAlign: "center", paddingBottom: "24px" }}>
              <a href="tel:+7188980871">(718) 898-0871</a>
            </h5>
            <iframe
              src="https://www.google.com/maps/embed/v1/place?q=69-64%20Grand%20Avenue%2C%20Maspeth%2C%20NY%2011378&amp;key=AIzaSyCHdqQDnzeG6CHiGK1rouCpjXwhk3DGE4I"
              width="100%"
              height="300"
            >
              <br />
            </iframe>
          </td>
        </tr>
      </table>
      <br />
      Thank you for your interest. We look forward to speaking with you soon.
      <br />
      Please contact us via phone at:{" "}
      <a href="tel:+2032212799">(203) 221-2799</a> (CT);{" "}
      <a href="tel:+7188980871">(718) 898-0871</a> (NY); via email{" "}
      <a href="mailto:info@meritfp.com">info@meritfp.com</a>
      <br />
      <br />
      <hr />
      <h4>Contact Form</h4>
      <br />
      <Form ref={form} onSubmit={sendEmail}>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Full Name</Form.Label>
          <Form.Control type="text" placeholder="Full Name" name="name" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="subject">
          <Form.Label>Subject</Form.Label>
          <Form.Control type="text" placeholder="Subject" name="subject" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="phone">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control type="text" placeholder="Phone Number" name="phone" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Email" name="email" />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="message">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Message"
            style={{ height: "100px" }}
            name="message"
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Send
        </Button>
      </Form>
      <br />
      Please note that we cannot accept instructions for trades or any other
      time-sensitive or financial information through this form.
      <br /> <br />
      Elizabeth Cox, CFP®, CPA <br />
      Michael Terry, CFP® <br />
      Hrefna S. Martin, CFP®, EA
      <br />
      Melissa Adams, CFP®
      <br />
      Ed Esposito, CFP®, EA
      <br />
      <br />
      Merit Financial Partners, LLC
      <br />
      An Independent Company | With You Every Step of the Way
    </PageContainerDivContent>
  );
};
