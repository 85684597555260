import { PageContainerDivContent } from "../../../components/PageContainer/styles";
import { ChecklistContainer } from "../../Services/styles";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { TRACKING_ID } from "../../../App";

export const BottomLinePlanningPackage = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/bottom-line-planning");
  }, []);
  return (
    <PageContainerDivContent>
      <Helmet>
        <title>
          The Bottom Line Planning Package | Merit Financial Partners
        </title>
        <meta
          name={"description"}
          content={
            "With the Bottom Line financial planning package, we jointly address your key questions and concerns in a time- and cost-efficient manner. Bottom Line includes a two-hour collaborative working session augmented by additional behind-the-scenes preparation, analysis, and documentation, culminating in the delivery of a limited-scope financial action plan."
          }
        />
      </Helmet>
      <div className="entry-wrap">
        <div className="entry-content content">
          <p>
            With the{" "}
            <strong>
              <span style={{ textDecoration: "underline;" }}>Bottom Line </span>
            </strong>
            financial planning package, we jointly address your key questions
            and concerns in a time- and cost-efficient manner.{" "}
            <strong>
              <span style={{ textDecoration: "underline;" }}>Bottom Line</span>
            </strong>{" "}
            includes a two-hour collaborative working session augmented by
            additional behind-the-scenes preparation, analysis, and
            documentation, culminating in the delivery of a limited-scope
            financial action plan.
          </p>
          <p>We focus on your most important financial issues, such as:</p>

          <ChecklistContainer>
            <div
              id="inbound-list"
              className="inbound-list class-CipeIJakWB fa-list-check"
            >
              <ul>
                <li>Budgeting,</li>
                <li>Debt Repayment and Refinancing</li>
                <li>Savings Plans</li>
                <li>Financial Goal Setting</li>
                <li>Retirement Resource Evaluation</li>
                <li>Insurance Needs and Needs Analysis</li>
                <li>Portfolio Diversification and Investment Selection</li>
                <li>Tax Planning and Tax Efficient Investment Strategies</li>
                <li>Retirement Plan Decisions</li>
                <li>Inheritance Proceeds Decisions.</li>
              </ul>
            </div>
          </ChecklistContainer>
          <p>
            Given its limited scope, A{" "}
            <strong>
              <span style={{ textDecoration: "underline;" }}>Bottom Line</span>
            </strong>{" "}
            package enables access to competent, objective financial advice for
            a significantly lower fee than a comprehensive financial plan.
          </p>
          <p>Price: $700-$1200</p>
        </div>
      </div>
    </PageContainerDivContent>
  );
};
