import {
  PageContainerDiv,
  PageContainerDivContent,
} from "../../../components/PageContainer/styles";
import michael from "../../../../src/images/about/michaelterry.jpg";
import {
  AboutImage,
  AboutImageContainer,
  AboutTable,
  AboutTableData,
  AboutTableRow,
  AboutImageText,
} from "../styles";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { TRACKING_ID } from "../../../App";

export const MichaelTerry = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/michael-terry");
  }, []);
  return (
    <PageContainerDivContent>
      <Helmet>
        <title>Michael Terry | Merit Financial Partners</title>
        <meta
          name={"description"}
          content={
            "Michael Terry CFP® is co-founder and Partner of Merit Financial Partners, LLC. Michael earned his CFP designation in 1995 while working as Editorial Financial Manager for Money Magazine. After a 20 year career in the magazine business, including stints at People Magazine, Time Magazine and 10 years at Money Magazine, Michael began to work full time as a fee-only financial adviser at FAM Corporation in Manhattan."
          }
        />
      </Helmet>
      <AboutTable>
        <AboutTableRow>
          <AboutTableData style={{ width: "60%" }}>
            Michael Terry CFP® is co-founder and Partner of Merit Financial
            Partners, LLC. Michael earned his CFP designation in 1995 while
            working as Editorial Financial Manager for Money Magazine. After a
            20 year career in the magazine business, including stints at People
            Magazine, Time Magazine and 10 years at Money Magazine, Michael
            began to work full time as a fee-only financial adviser at FAM
            Corporation in Manhattan. In 2006 he founded Michael Terry Planning
            in Maspeth Queens in order to serve a broader spectrum of clientele
            and to fulfill a need for community service.
            <p>
              Michael is a frequent contributor and expert advisor to
              publications like Money Magazine, Consumer Reports and the Queens
              Ledger. In 2013, he wrote a series of articles for the Queens
              Ledger on Mutual Funds. Michael brings his expertise to bear on
              early retirement strategies, asset allocation and mutual fund
              strategies, College Planning, and general financial planning. He
              has an MBA from NYU, and a Bachelor of Music and Master of Music
              from the University of Memphis.
            </p>
            <p>
              Michael is currently the Executive Vice-President of the Maspeth
              Chamber of Commerce and Past Distinguished President of the
              Kiwanis Club of Maspeth. He served as Lt. Governor of the Queens
              West Division of Kiwanis International for the 2011-2012 fiscal
              year and continues his Kiwanis work as secretary of the Maspeth
              Club. He lectures around the Tri-state area on College Financial
              Planning and Financial Aid. He has served on the executive boards
              of several Parent Associations including Bronx High School of
              Science and the LaGuardia High School for the Performing Arts and
              as a Cub Master for Pack 365 and Assistant Scout Master for Troop
              45 in Maspeth. He enjoys Bird Watching, Camping, Opera, Baroque
              and Classical Music as well as Jazz and other forms of music. His
              wife, Lisa, is a well-known Baroque Musician, featuring the cello
              and Viola da Gamba.
            </p>
          </AboutTableData>
          <AboutTableData>
            <AboutImageContainer>
              <AboutImage src={michael} />
              <AboutImageText>
                <h4>Michael Terry</h4>
                CFP®
                <br />
                <a href="mailto:mterry@meritfp.com">Email Me</a>
              </AboutImageText>
            </AboutImageContainer>
          </AboutTableData>
        </AboutTableRow>
      </AboutTable>
    </PageContainerDivContent>
  );
};
